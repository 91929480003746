import React from "react";

import Layout from "../components/Layout";
import AuthorsSection from "../content/AuthorsSection";

const Authors = (props) => {
  const { location, pageContext } = props;
  return (
    <Layout>
      <AuthorsSection
        hash={location.hash}
        isPage
        categories={pageContext.categoryOptions}
      />
    </Layout>
  );
};

export default Authors;
